import type { Unsubscribe } from 'firebase/auth'
import { getAuth } from 'firebase/auth'
import formatUser from '~/helpers/format-user'

// Global auth token used to show fullscreen spinner whilst firebase auth inits.
import { useGlobalAuthToken } from "~/stores/globalAuthToken";
import { promiseTimeout, useTimeout } from '@vueuse/core'


/**
 * Sets user composable and token cookie value
 * based on sign-in, sign-out, and token refresh events.
 */
export default function () {
  const { $firebaseAuth } = useNuxtApp()

  // Global auth token
  const globalAuthToken = useGlobalAuthToken()

  const token = useCookie('wetix-token', {
    path: '/',
    maxAge: 1000
  })

  const firebaseUser = useUser()

  let unsubscribe: Unsubscribe

  onMounted(() => {
    unsubscribe = $firebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        const auth = getAuth()

        const userIdToken = await auth.currentUser.getIdToken(/* forceRefresh */ true)

        // console.log(`Got ID token: `, userIdToken)
        await promiseTimeout(5000)
        // Get the user ID token.
        // token.value = await user.getIdToken()
        globalAuthToken.value = userIdToken
        token.value = userIdToken
        firebaseUser.value = formatUser(user)
      }
      else {
        token.value = null
        firebaseUser.value = null
      }
    })
  })

  onUnmounted(() => {
    unsubscribe?.()
  })
}
